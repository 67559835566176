<template>
    <div class="game-navbar" :style="cssStyle">
        <div class="stepping-stone"></div>
        <div class="layout">
            <div class="inner">
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="pictureUrl" alt="" />
                    </div>
                    <div class="user-name">{{ displayName }}</div>
                </div>
                <div class="button-set">
                    <router-link to="/game/map" class="map">
                        <div>闖關</div>
                        <div>地圖</div>
                    </router-link>
                    <router-link to="/" class="desc">
                        <div>活動</div>
                        <div>辦法</div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            displayName: (state) => state.user.displayName,
            pictureUrl: (state) => state.user.pictureUrl,
        }),
        cssStyle() {
            return `--navbar-height: ${this.$root.navbarHeight}px`;
        },
    },
};
</script>

<style lang="scss" scoped>
$navbar-height: var(--navbar-height);

.game-navbar {
    position: relative;
    height: $navbar-height;
    .stepping-stone {
        width: 100%;
    }
    .layout {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        height: $navbar-height;
        background-color: #fff;
        border-bottom: 3px solid #dac786;
    }
    .layout .inner {
        width: min(90%, 1440px);
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .user-info {
            flex: 1;
            display: flex;
            align-items: center;
            height: 100%;
            .user-avatar {
                width: 40px;
                height: 40px;
                img {
                    border-radius: 50%;
                }
            }
            .user-name {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 10px;
            }
        }
        .button-set {
            display: flex;
            > * {
                cursor: pointer;
                background-color: #ed6f6f;
                font-size: 15px;
                line-height: 1.27;
                color: #fff;
                width: 50px;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .user-info .user-name {
            width: 200px;
        }
    }
    @media (max-width: 414px) {
        .user-info .user-name {
            width: 120px;
        }
    }
}
</style>
