<template>
  <div class="game">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import liff from '@line/liff';
import { initUser } from '@/db';

import Navbar from '@/components/Game/Navbar.vue';

export default {
  components: { Navbar },
  async created() {
    if (process.env.VUE_APP_MODE != 'DEV') {
      const liffId = process.env.VUE_APP_LIFF_ID;
      liff.init({ liffId });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          const RES = await liff.getProfile();
          const { profile, progress } = await initUser(RES);
          this.setUser(profile);
          this.setProgress(progress);
        } else liff.login();
      });
    }
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
      setProgress: 'setProgress',
    }),
  },
};
</script>

<style lang="scss" scoped>
.game {
  background-color: #fffde9;
  background-image: url('../../assets/game/bg.jpg');
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  color: #4c2a12;

  display: flex;
  flex-direction: column;
}
</style>
